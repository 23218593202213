import React, { useEffect, useState, useRef } from 'react'
import { SpinnerComponent } from 'react-element-spinner'
import { Navbar, Button } from 'react-bootstrap'
import Lightbox from 'react-image-lightbox'
import { ToastContainer, toast } from 'react-toastify'
import { ImageCapture } from 'image-capture'
import { compress } from 'image-conversion'

// import cvBridge from './services/cvBridge'

import 'react-toastify/dist/ReactToastify.css'
import 'react-image-lightbox/style.css'
import 'bootstrap/dist/css/bootstrap.min.css'

// import * as cv from './vendor/opencv'

const App = () => {
  const RARootURL = process.env.REACT_APP_RA_APP_URL

  const [isOpen, setIsOpen] = useState(false)
  const [document, setDocument] = useState(null)
  const [token] = useState(new URLSearchParams(window.location.search).get('token'))
  const [uploading, setUploading] = useState(false)
  const [uploadComplete, setUploadComplete] = useState(null)
  // const [toastShow, setToastShow] = useState(true)
  // const [openCVLoaded, setOpenCVLoaded] = useState(false)
  const [canvasSize, setCanvasSize] = useState({ width: window.innerWidth, height: window.innerHeight})
  const [videoSize, setVideoSize] = useState({ width: 4096, height: 2160})
  const [imageCapture, setImageCapture] = useState(null)
  const canvasContext = useRef(null)

  const videoRef = useRef(null)
  const canvasEl = useRef(null)
  const navbarRef = useRef(null)

  const videoConstraints = { video: { facingMode: 'environment', width: { ideal: videoSize.width }, height: { ideal: videoSize.height } } }

  const messages = {
    invalidToken: 'Invalid Token from Robot Accounts',
    readyToScan: 'Double-tap to Scan',
    documentUploadSuccess: 'Successfully uploaded document to Robot Accounts',
    documentUploadError: 'Error when uploading to Robot Accounts. Please try again'
  }

  const handleResize = () => {
    setCanvasSize({
      height: window.innerHeight,
      width: window.innerWidth
    })
  }

  useEffect(() => {
    if (isOpen) {
      videoRef.current.pause()
    } else {
      videoRef.current.play()
    }
  }, [isOpen])

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return _ => {window.removeEventListener('resize', handleResize)}
  })

  // const setupScanner = async () => {
  //   let scannerInit = await ScanbotSDK.initialize({ path: "/" })

  //   setScanner(scannerInit)
  //   await scannerInit.createDocumentScanner(scannerConfiguration)
  // }

  const RAURLBuilder = (path, params) => {
    let url = new URL(RARootURL + path)
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

    return url
  }

  const toaster = (kind, message, ...options) => {
    toast[kind](message, ...options)
  }

  const prepareForNewScan = () => {
    console.debug('preparing for new scan')
    setUploading(false)
    setUploadComplete(null)
    setIsOpen(false)
    setDocument(null)
  }

  const prepareForReupload = () => {
    console.debug('preparing for rescan')
    setUploading(false)
    setUploadComplete(null)
    setIsOpen(true)
  }

  const validateToken = async () => {
    let url = RAURLBuilder('/users/validate_token', { token })
    let response = await fetch(url)
    console.debug('validation response from RA:', response)

    return response.ok
  }

  // constructor
  useEffect(() => {
    if (token === "") {
      toaster('error', messages.invalidToken)
      return
    }

    validateToken().then(tokenIsValid => {
      if (tokenIsValid) {
        toaster('success', messages.readyToScan)
      } else {
        toaster('error', messages.invalidToken)
      }
    })
  }, [])

  // const checkIfEnvironmentCamera = (device) => {
  //   return device.label.includes('Back') // on iPhone back camera is environment
  // }

  useEffect(() => {
    let videoElement = videoRef.current
    let canvas = canvasEl.current
    let context = canvas.getContext("2d")
    canvasContext.current = context
    var crop, raf
    navigator.mediaDevices.getUserMedia(videoConstraints).then((stream) => {
      videoElement.onplaying = () => {
        setVideoSize({width: videoElement.videoWidth, height: videoElement.videoHeight})
        // crop = { w: videoElement.videoWidth, h: videoElement.videoHeight, x: 0, y: 0 };
        crop = { w: videoElement.videoWidth, h: videoElement.videoHeight, x: 0, y: 0 };
        // call our loop only when the video is playing
        raf = requestAnimationFrame(videoCopyLoop);
      }
      videoElement.srcObject = stream

      const mediaStreamTrack = stream.getVideoTracks()[0]
      setImageCapture(new ImageCapture(mediaStreamTrack))
    })

    // iOS Compatibilty - by default iOS does not let a video autoplay without interaction even if the autoplay
    // property is set on the video element. NBD, we just call .play(). Works for all browsers, but iOS is picky
    // and doesn't work without it...
    videoElement.play()

    const videoCopyLoop = () => {
      context.drawImage(videoElement, crop.x, crop.y, crop.w, crop.h, 0, 0, canvas.width, canvas.height)
      raf = requestAnimationFrame(videoCopyLoop)
    }

    context.translate(canvasEl.current.width, 0);
    context.scale(1, 1)
  }, [])

  // on document change
  useEffect(() => {
    if (document) {
      setIsOpen(true)
      console.debug("cropped_document length", document.cropped.length)
    }
  }, [document])

  // on uploadComplete change
  useEffect(() => {
    if (uploadComplete) {
      toaster('success', messages.documentUploadSuccess)
      prepareForNewScan()
    } else if (uploadComplete === false) { // have to ask if it is false here, as !uploadComplete is when it is null too
      toaster('error', messages.documentUploadError)
      prepareForReupload()
    }

    console.debug("uploadComplete", uploadComplete)
  }, [uploadComplete, messages.documentUploadError, messages.documentUploadSuccess])

  // useEffect(() => {
  //   async function loadCV() {
  //     return await cvBridge.load()
  //   }

  //   loadCV().then(val => {
  //     setOpenCVLoaded(val.data.status === 'success')
  //   })
  // }, [])

  const sendToRA = () => {
    console.debug('beginning upload to RA...')
    setIsOpen(false) // close the lightbox
    setUploading(true) // show the loading spinner
    let url = RAURLBuilder('/uploaded_documents/create_from_scanner_app', { token })

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ base64_document: { cropped: document.cropped } })
    }).then(response => {
      console.debug('upload response from RA:', response)
      setUploadComplete(response.ok)
    }).catch(() => {
      setUploadComplete(false)
    });
  }

  // Image Processing Hook
  // useEffect(() => {
  //   if (openCVLoaded) {
  //     const processCamera = async () => {
  //       let context = canvasEl.current.getContext('2d')
  //       let image = context.getImageData(0, 0, videoSize.width, videoSize.height)
  //       let processedImage = await cvBridge.processBoundaries(image)

  //       if (processedImage) {
  //         let { rows, cols, data } = processedImage.data.finalData
  //         let returnedImage = cv.matFromArray(cols, rows, cv.CV_8UC1, data)

  //         cv.imshow('canvasOutput', returnedImage)
  //       }
  //       // debugger
  //     }
  //     setInterval(() => {
  //       processCamera()
  //     }, 2000);
  //   }
  // }, [openCVLoaded])

  const onCanvasClick = (e) => {
    if (e.detail === 2) {
      imageCapture.takePhoto().then(blob => {
        compress(blob, 0.6).then(res => {
          var reader = new FileReader();
          reader.readAsDataURL(res);
          reader.onloadend = function () {
            var base64data = reader.result;
            setDocument({ original: base64data, cropped: base64data })
          }
        })
      })
    }
  }

  return (
    <>
      <div className="container-fluid p-0">
        <SpinnerComponent loading={uploading} position="global" message="Uploading to Robot Accounts..." />

        <Navbar ref={navbarRef} bg="dark" variant="dark">
          <Navbar.Brand href="#home">
            Robot Accounts Scanner
          </Navbar.Brand>
        </Navbar>

        <ToastContainer />
          <div className="col-12 p-0">
            <canvas id='imageCanvas' width={canvasSize.width} height={canvasSize.height - (navbarRef.current?.offsetHeight || 0)} ref={canvasEl} onClick={onCanvasClick}></canvas>
            <video style={{ display: 'block', opacity: 0, height: 0, width: 0}} ref={videoRef} id="camera" autoPlay playsInline />
          </div>

          {/* <div className="col-12 p-0">
            <canvas ref={canvasOutputRef} id='canvasOutput' width={canvasSize.width} height={canvasSize.height} ></canvas>
          </div> */}


        {
          isOpen && (
            <>
              <Lightbox
                mainSrc={document.cropped ? document.cropped : document.original}
                onCloseRequest={() => setIsOpen(false)}
                toolbarButtons={[<Button variant="primary" onClick={sendToRA}>Send to Robot Accounts</Button>]}
              />
            </>
          )
        }
      </div>
    </>
  );
}

export default App;
